import React, { useState, useEffect, useCallback } from 'react';
import { useMotionValue, motion, useMotionTemplate } from 'framer-motion';
import productImage from '../assets/logo.png';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const vipData = [
  {
    title: "VIP BRONZE",
    price: 37.00,
    color: "205, 127, 50",
    benefits: [
      "5% de desconto em imposto da cidade (30 dias)",
      "1 carro da categoria EXECUTIVO (30 dias)",
      "+1 vaga na garagem",
      "Prioridade na fila de login nivel BRONZE",
      "Salario na cidade $100 a cada 10 minutos online",
      "Pagamento unico $1000",
      "Interior de casa alugada BRONZE (SEASON)",
      "Não perde a mochila e telefone (30 DIAS)",
      "Peso extra na mochila 10 kgs",
      "Roupas, Tatuagens e Cabelos exclusivos (30 dias)",
      "Acesso ao chat vip no discord (prioridade com Staff)",
    ],
  },
  {
    title: "VIP PRATA",
    price: 77.00,
    color: "192, 192, 192",
    benefits: [
      "8% de desconto em imposto da cidade (30 dias)",
      "2 carros da categoria EXECUTIVO (30 dias)",
      "+2 vagas na garagem",
      "Prioridade na fila de login nivel PRATA",
      "Salario na cidade $150 a cada 10 minutos online",
      "Pagamento unico $2000",
      "Interior de casa alugada PRATA (SEASON)",
      "Limite de residencia extra 1",
      "Não perde a mochila e telefone (30 DIAS)",
      "Peso extra na mochila 20 kgs",
      "Roupas, Tatuagens e Cabelos exclusivos (30 dias)",
      "Acesso ao youtube e soundfy no celular",
      "Acesso ao chat vip no discord (prioridade com Staff)",
    ],
  },
  {
    title: "VIP OURO",
    price: 127.00,
    color: "255, 215, 0",
    benefits: [
      "12% de desconto em imposto da cidade (30 dias)",
      "1 carro da categoria EXECUTIVO (Permanente)",
      "1 carro da categoria PREMIUM (30 dias)",
      "+2 vagas na garagem",
      "Prioridade na fila de login nivel OURO",
      "Salario na cidade $200 a cada 10 minutos online",
      "Pagamento unico $3000",
      "Interior de casa alugada OURO (SEASON)",
      "Limite de residencia extra 2",
      "Não perde a mochila e telefone (30 DIAS)",
      "Peso extra na mochila 30 kgs",
      "Roupas, Tatuagens e Cabelos exclusivos (30 dias)",
      "1 placa de carro personalizada",
      "Acesso ao youtube e soundfy no celular",
      "Acesso ao comando da tv",
      "Acesso ao chat vip no discord (prioridade com Staff)",
    ],
  },
  {
    title: "VIP PLATINA",
    price: 187.00,
    color: "229, 228, 226",
    benefits: [
      "15% de desconto em imposto da cidade (30 dias)",
      "1 carro da categoria PREMIUM (30 dias)",
      "1 carro da categoria LUXO (Permanente)",
      "+2 vagas na garagem",
      "Prioridade na fila de login nivel PLATINA",
      "Salario na cidade $250 a cada 10 minutos online",
      "Pagamento unico $4000",
      "Interior de casa alugada PLATINA (SEASON)",
      "Limite de residencia extra 2",
      "Não perde a mochila e telefone (30 DIAS)",
      "Peso extra na mochila 40 kgs",
      "Roupas, Tatuagens e Cabelos exclusivos (30 dias)",
      "2 placas de carro personalizada",
      "Acesso ao youtube e soundfy no celular",
      "Acesso ao comando da tv",
      "Verificado no instagram",
      "Acesso ao chat vip no discord (prioridade com Staff)",
    ],
  },
  {
    title: "VIP DIAMANTE",
    price: 257.00,
    color: "185, 242, 255",
    benefits: [
      "20% de desconto em imposto da cidade (30 dias)",
      "1 carro da categoria SUPER LUXO (Permanente)",
      "1 carro da categoria BLINDADOS (30 dias)",
      "+3 vagas na garagem",
      "Prioridade na fila de login nivel DIAMANTE",
      "Salario na cidade $300 a cada 10 minutos online",
      "Pagamento unico $5000",
      "Interior de casa alugada DIAMANTE (SEASON)",
      "Limite de residencia extra 3",
      "Não perde a mochila e telefone (30 DIAS)",
      "Peso extra na mochila 50 kgs",
      "Roupas, Tatuagens e Cabelos exclusivos (30 dias)",
      "3 placas de carro personalizada",
      "Acesso ao youtube e soundfy no celular",
      "Acesso ao comando da tv",
      "Verificado no instagram",
      "Smartwatch",
      "Acesso ao chat vip no discord (prioridade com Staff)",
    ],
  },
];

const CardProduct = ({ title, price, benefits, color, onPurchase }) => {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  return (
    <motion.div
      onMouseMove={(e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        mouseX.set(e.clientX - left);
        mouseY.set(e.clientY - top);
      }}
      className="group relative max-w-[350px] w-full overflow-hidden rounded-xl bg-neutral-950"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="absolute right-5 top-0 h-px w-80 bg-gradient-to-l from-transparent via-white/30 via-10% to-transparent" />
      <motion.div
        className="pointer-events-none absolute -inset-px rounded-xl opacity-0 transition duration-300 group-hover:opacity-100"
        style={{
          background: useMotionTemplate`
              radial-gradient(200px circle at ${mouseX}px ${mouseY}px, rgba(${color}, 0.4), transparent 80%)
            `,
        }}
      />
      <div className="relative flex flex-col gap-3 rounded-xl border border-white/10 px-4 py-5" style={{ minHeight: '450px' }}>
        <div className="space-y-2">
          <img
            src={productImage}
            alt="Product"
            height={200}
            width={350}
            className="rounded-xl h-52 w-full object-cover opacity-75"
          />
          <div className="flex flex-row items-center justify-between pt-2">
            <h3 className="text-xl font-semibold" style={{ color: `rgb(${color})` }}>{title}</h3>
            <p className="text-[13px] text-neutral-300 select-none">{`R$ ${price.toFixed(2)}`}</p>
          </div>
          <p className="text-sm leading-[1.5] text-neutral-400 pb-3">
            {benefits.map((benefit, index) => (
              <span key={index}>
                [+] {benefit}
                <br />
              </span>
            ))}
          </p>
          <button
            className="inline-flex items-center justify-center gap-1 text-sm py-3 px-4 font-semibold bg-white text-black rounded-lg duration-300 hover:bg-white/70 w-full"
            onClick={onPurchase}
          >
            COMPRAR
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const Modal = ({ isOpen, onClose, onConfirm }) => {
  const [citizenID, setCitizenID] = useState('');

  const handleConfirm = () => {
    onConfirm(citizenID);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-black p-6 rounded-lg">
        <h2 className="text-xl text-white mb-4">Insira seu Citizen ID</h2>
        <input
          type="text"
          value={citizenID}
          onChange={(e) => setCitizenID(e.target.value)}
          className="border p-2 mb-4 w-full"
        />
        <button onClick={handleConfirm} className="bg-blue-500 text-white p-2 rounded mr-2">Confirmar</button>
        <button onClick={onClose} className="bg-red-500 text-white p-2 rounded">Cancelar</button>
      </div>
    </div>
  );
};

const Vips = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [intervalTime, setIntervalTime] = useState(3000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? vipData.length - 1 : prevIndex - 1
    );
    setIntervalTime(40000);
  }, []);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === vipData.length - 1 ? 0 : prevIndex + 1
    );
    setIntervalTime(40000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [handleNext, intervalTime]);

  const handlePurchase = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleConfirmPurchase = async (citizenID) => {
    setIsModalOpen(false);
    if (!selectedProduct) return;

    const { title, price } = selectedProduct;

    try {
      const response = await fetch('https://store.rpdowntown.com/api/create_preference.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ price, title, citizenID }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      window.location.href = data.init_point;
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  return (
    <div id="vips" className="relative w-full flex flex-col items-center justify-center">
      <h1 className="text-3xl font-bold text-white mb-4">VIPs</h1>
      <p className="text-white text-center mb-8">
        Os VIPs dão benefícios temporários e/ou permanentes dentro da cidade.
        Aproveite para adquirir e obter vantagens exclusivas!
      </p>
      <div className="relative w-full flex items-center justify-center">
        <button
          className=" mx-5 bg-neutral-950 text-white rounded-full p-2 hover:bg-neutral-800"
          onClick={handlePrev}
        >
          <FaArrowLeft />
        </button>
        <motion.div
          key={currentIndex}
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <CardProduct
            title={vipData[currentIndex].title}
            price={vipData[currentIndex].price}
            benefits={vipData[currentIndex].benefits}
            color={vipData[currentIndex].color}
            onPurchase={() => handlePurchase(vipData[currentIndex])}
          />
        </motion.div>
        <button
          className="mx-5 bg-neutral-950 text-white rounded-full p-2 hover:bg-neutral-800"
          onClick={handleNext}
        >
          <FaArrowRight />
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmPurchase}
      />
    </div>
  );
};

export default Vips;