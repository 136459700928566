import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';

const NavbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 20px 0;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  margin: 0 20px;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const NavSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  &:nth-child(2) {
    justify-content: center;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
`;

const NavLogoImage = styled.img`
  height: 60px;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  &:hover {
    color: #6A1B9A;
  }
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-center;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #000, #222, #000);
  background-size: 400% 400%;
  border: 1px solid #444;
  border-radius: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    animation: shine 2s linear infinite;
    background-position: 200% 0;
  }
`;

const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 20px;
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  border: 1px solid #444;
  border-radius: 10px;
  width: 200px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  &.open {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DropdownItem = styled.a`
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.logout {
    color: red;
  }
`;

const Navbar = ({ logoSrc }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const loggedIn = document.cookie.includes('loggedInDiscord=true');
    if (loggedIn) {
      const avatarCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('discordAvatar='))
        ?.split('=')[1];
      if (avatarCookie) {
        setProfileImage(decodeURIComponent(avatarCookie));
        setIsLoggedIn(true);
      }
    }

    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const loginDiscord = () => {
    const clientId = "1221923204759683252";
    const redirectUri = encodeURIComponent("http://localhost:3000/discord");
    const scope = encodeURIComponent("identify email guilds");
    const responseType = "token";

    const url = `https://discord.com/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}`;

    window.location.href = url;
  };

  const handleProfileClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    document.cookie.split(';').forEach((cookie) => {
      const cookieName = cookie.trim().split('=')[0];
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
    });
    window.location.reload();
  };

  const handleAccessPurchases = () => {
    window.location.href = 'https://home.rpdowntown.com/compras';
  };

  return (
    <NavbarWrapper>
      <NavbarContainer>
        <NavSection>
          <NavLinks>
            <NavLink href="#vips">VIPs</NavLink>
            <NavLink href="#diamantes">Diamantes</NavLink>
            <NavLink href="https://www.rpdowntown.com:30120">Conectar</NavLink>
            <NavLink href="https://discord.gg/rpdowntown">Contato</NavLink>
          </NavLinks>
        </NavSection>
        <NavSection>
          {logoSrc && <NavLogoImage src={logoSrc} alt="Logo" />}
        </NavSection>
        <NavSection>
          <NavLinks>
            {isLoggedIn ? (
              <>
                <ProfileImage src={profileImage} alt="Profile" onClick={handleProfileClick} />
                <div ref={dropdownRef}>
                  <DropdownMenu className={dropdownOpen ? 'open' : ''}>
                    <DropdownItem onClick={handleAccessPurchases}>Acessar Compras</DropdownItem>
                    <DropdownItem className="logout" onClick={handleLogout}>Deslogar</DropdownItem>
                  </DropdownMenu>
                </div>
              </>
            ) : (
              <Button onClick={loginDiscord}>Entrar</Button>
            )}
          </NavLinks>
        </NavSection>
      </NavbarContainer>
    </NavbarWrapper>
  );
};

export default Navbar;
