import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import LoadingScreen from './components/LoadingScreen';
import Navbar from './components/Navbar';
import Connect from './components/Connect';
import BemVindo from './components/BemVindo';
import Vips from './components/Vips';
import Diamantes from './components/Diamantes';
import './index.css';
import logoImage from './assets/logo.png';
import Footer from './components/Footer';

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoadComplete = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    console.log("Checking for access token in URL...");
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    if (fragment.has("access_token")) {
      const accessToken = fragment.get("access_token");
      console.log("Access token found:", accessToken);
      fetch("https://discord.com/api/users/@me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((user) => {
          document.cookie = `loggedInDiscord=true; path=/; max-age=86400`;
          document.cookie = `discordUsername=${encodeURIComponent(
            user.username
          )}#${encodeURIComponent(user.discriminator)}; path=/; max-age=86400`;
          document.cookie = `discordEmail=${encodeURIComponent(
            user.email
          )}; path=/; max-age=86400`;

          const avatarURL = user.avatar
            ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
            : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`;
          document.cookie = `discordAvatar=${encodeURIComponent(
            avatarURL
          )}; path=/; max-age=86400`;

          console.log("Cookies setados. Redirecionando para register.php...");
          window.location.href = "register";
        })
        .catch((error) => {
          console.error("Erro ao buscar dados do usuário:", error);
        });
    } else {
      console.log(
        "Nenhum token de acesso encontrado. Verifique se o fluxo de autenticação está correto."
      );
    }
  }, []);


  return (
    <div className="App">
      {!isLoaded && <LoadingScreen onLoadComplete={handleLoadComplete} />}
      {isLoaded && (
        <>
          <Navbar logoSrc={logoImage} />
          <Connect />
          <BemVindo />
          <Vips />
          <Diamantes />
          <Footer />
        </>
      )}
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
