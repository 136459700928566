import React from 'react';
import styled from 'styled-components';

const BemVindoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
`;

const BemVindoHeader = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
`;

const BemVindoText = styled.p`
  font-size: 1.25rem;
  color: #ccc;
  max-width: 600px;
`;

const BemVindo = () => {
  return (
    <BemVindoWrapper>
      <BemVindoHeader>Bem-vindo à Loja Downtown</BemVindoHeader>
      <BemVindoText>
        Aqui você encontrará VIPs e Diamantes para o maior servidor de RP da América Latina. Se tiver alguma dúvida, abra um ticket no Discord e nossa equipe estará pronta para ajudar!
      </BemVindoText>
    </BemVindoWrapper>
  );
};

export default BemVindo;
