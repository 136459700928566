import React, { useState, useEffect, useCallback } from 'react';
import { useMotionValue, motion, useMotionTemplate } from 'framer-motion';

const diamondOptions = [
  { price: 25, diamonds: 25 },
  { price: 60, diamonds: 60 },
  { price: 120, diamonds: 120 },
  { price: 240, diamonds: 240 },
  { price: 600, diamonds: 600 },
  { price: 1200, diamonds: 1200 },
];

const CardProduct = ({ price, diamonds, onPurchase }) => {
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  return (
    <motion.div
      onMouseMove={(e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        mouseX.set(e.clientX - left);
        mouseY.set(e.clientY - top);
      }}
      className="group relative max-w-[250px] w-full overflow-hidden rounded-xl bg-neutral-90"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="pointer-events-none absolute -inset-px rounded-xl opacity-0 transition duration-300 group-hover:opacity-100"
        style={{
          background: useMotionTemplate`
            radial-gradient(200px circle at ${mouseX}px ${mouseY}px, rgba(0, 161, 255, 0.4), transparent 80%)
          `,
        }}
      />
      <div className="relative flex flex-col gap-3 rounded-xl border border-white/10 px-4 py-5" style={{ minHeight: '200px' }}>
        <div className="space-y-2">
          <div className="flex flex-row items-center justify-between pt-2">
            <h3 className="text-xl font-semibold text-neutral-200">R$ {price.toFixed(2)}</h3>
          </div>
          <p className="text-sm leading-[1.5] text-neutral-400 pb-3">
            {diamonds} Diamantes
          </p>
          <button
            className="inline-flex items-center justify-center gap-1 text-sm py-3 px-4 font-semibold bg-white text-black rounded-lg duration-300 hover:bg-white/70 w-full"
            onClick={onPurchase}
          >
            COMPRAR
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const Modal = ({ isOpen, onClose, onConfirm }) => {
  const [citizenID, setCitizenID] = useState('');

  const handleConfirm = () => {
    onConfirm(citizenID);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-black p-6 rounded-lg">
        <h2 className="text-xl text-white mb-4">Insira seu Citizen ID</h2>
        <input
          type="text"
          value={citizenID}
          onChange={(e) => setCitizenID(e.target.value)}
          className="border p-2 mb-4 w-full"
        />
        <button onClick={handleConfirm} className="bg-blue-500 text-white p-2 rounded mr-2">Confirmar</button>
        <button onClick={onClose} className="bg-red-500 text-white p-2 rounded">Cancelar</button>
      </div>
    </div>
  );
};

const Diamantes = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handlePurchase = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleConfirmPurchase = async (citizenID) => {
    setIsModalOpen(false);
    if (!selectedProduct) return;

    const { price, diamonds } = selectedProduct;
    const title = `${diamonds} Diamantes`;

    try {
      const response = await fetch('https://store.rpdowntown.com/api/create_preference.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ price, title, citizenID }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      window.location.href = data.init_point;
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  return (
    <div id="diamantes" className="flex flex-col items-center justify-center py-8 bg-black">
      <h1 className="text-4xl font-bold text-white mb-8">Diamantes</h1>
      <p className="text-lg text-neutral-400 mb-8 text-center px-4 md:px-8">
        Os diamantes são usados para comprar itens dentro da loja in-game. Você pode adquirir diferentes pacotes de diamantes para aproveitar ao máximo as ofertas e benefícios disponíveis. Cada pacote oferece uma quantidade específica de diamantes que podem ser usados para comprar veículos, propriedades e outras vantagens exclusivas.
      </p>
      <div className="flex flex-wrap justify-center gap-4">
        {diamondOptions.map((option, index) => (
          <CardProduct
            key={index}
            price={option.price}
            diamonds={option.diamonds}
            onPurchase={() => handlePurchase(option)}
          />
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmPurchase}
      />
    </div>
  );
};

export default Diamantes;
