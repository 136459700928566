import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Velustro } from 'uvcanvas';
import CircularProgress from '@mui/material/CircularProgress';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(270deg, #000000, #333333, #000000);
  background-size: 600% 600%;
  animation: ${gradientAnimation} 8s ease infinite;
`;

const UVContainer = styled.div`
  width: 500px;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircularTextContainer = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  animation: ${rotate} 2s linear infinite, ${({ theme }) => css`${fadeOut} 2s ease-in-out 2s forwards`};
`;

const CenterText = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: #000;
  position: absolute;
  opacity: 0;
  animation: ${fadeIn} 1s ease-in-out forwards;
`;

const LoadingScreen = ({ onLoadComplete }) => {
  const [loading, setLoading] = useState(true);
  const [showCenterText, setShowCenterText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setShowCenterText(true);
    }, 3000);

    const finalTimer = setTimeout(() => {
      onLoadComplete();
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(finalTimer);
    };
  }, [onLoadComplete]);

  return (
    <LoadingContainer>
      <UVContainer>
        <Velustro style={{ width: '100%', height: '100%' }} />
        {loading && (
          <CircularTextContainer>
            <CircularProgress size={80} thickness={4} style={{ color: '#000' }} />
          </CircularTextContainer>
        )}
        {showCenterText && (
          <CenterText>
            DowntownRP
          </CenterText>
        )}
      </UVContainer>
    </LoadingContainer>
  );
};

export default LoadingScreen;
