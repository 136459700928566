import React from 'react';
import styled from 'styled-components';
import serverImage from '../assets/server-image.png';

const ConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(48, 25, 52, 1) 50%, rgba(0, 0, 0, 1) 100%);
  padding: 50px 0;
`;

const ConnectImage = styled.img`
  max-width: 80%;
  transition: opacity 2s ease-in-out;
  opacity: 0.9;
`;

const ConnectText = styled.h1`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(110deg, #fff 45%, #1e2631 55%, #939393);
  background-size: 200% 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 2s linear infinite;
`;


const Connect = () => {
  return (
    <ConnectWrapper>
      <ConnectImage src={serverImage} alt="Servidor" />
      <ConnectText>O MAIOR SERVIDOR DE RP DA AMÉRICA LATINA</ConnectText>
    </ConnectWrapper>
  );
};

export default Connect;
