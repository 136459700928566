import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-neutral-980 text-white py-8">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Downtown Roleplay. Todos os direitos reservados.
        </p>
        <p className="mt-4 text-sm">
          Qualquer compra no site será considerada uma doação e não tem direito a reembolso. Caso ocorra algum erro com o produto adquirido, você terá direito a suporte e uma avaliação para determinar se devemos trocar ou garantir novos benefícios.
        </p>
        <p className="mt-4 text-sm">
          Agradecemos pelas doações, pois elas mantêm o servidor ativo e permitem a implementação de novas melhorias.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
